import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuthInstance } from '../firebase';
import TabNavigation from '../components/settings/TabNavigation';
import ProfileTab from '../components/settings/ProfileTab';
import BillingTab from '../components/settings/BillingTab';
import NotificationsTab from '../components/settings/NotificationsTab';
import MediaLibraryTab from '../components/settings/MediaLibraryTab';
import { useSettings } from '../contexts/SettingsContext';

// Utility function to prevent body scroll
const preventBodyScroll = (prevent) => {
  if (prevent) {
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
    document.body.style.height = '100%';
  } else {
    document.body.style.overflow = '';
    document.body.style.position = '';
    document.body.style.width = '';
    document.body.style.height = '';
  }
};

const CloseButton = ({ onClose }) => (
  <button
    onClick={onClose}
    className="absolute right-6 top-6 text-gray-400 hover:text-gray-600"
  >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
      <path d="M18 6L6 18M6 6l12 12" />
    </svg>
  </button>
);

const ProfileSettings = ({ isOpen, onClose, initialTab = 'profile' }) => {
  const auth = getAuthInstance();
  const [user] = useAuthState(auth);
  const [activeTab, setActiveTab] = useState(initialTab);

  // Add effect to update activeTab when initialTab changes
  useEffect(() => {
    if (initialTab) {
      setActiveTab(initialTab);
    }
  }, [initialTab]);

  // Add useEffect to manage body scroll
  useEffect(() => {
    if (isOpen) {
      preventBodyScroll(true);
    }
    return () => preventBodyScroll(false);
  }, [isOpen]);

  const renderTabContent = () => {
    switch (activeTab) {
      case 'profile':
        return <ProfileTab user={user} />;
      case 'billing':
        return <BillingTab />;
      case 'notifications':
        return <NotificationsTab />;
      case 'media':
        return <MediaLibraryTab />;
      default:
        return null;
    }
  };

  return isOpen ? (
    <div 
      className="fixed inset-0 bg-black/50 z-[90]"
      onClick={onClose}
    >
      <div 
        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl shadow-xl w-[1150px] h-[750px] overflow-hidden"
        onClick={(e) => e.stopPropagation()}
      >
        <CloseButton onClose={onClose} />

        <div className="flex items-center h-[65px] px-7 border-b">
          <h2 className="text-2xl font-semibold">Settings</h2>
        </div>

        <div className="h-[685px] grid grid-cols-12">
          <div className="col-span-3 border-r">
            <TabNavigation activeTab={activeTab} onTabChange={setActiveTab} />
          </div>

          <div className="col-span-9 p-7 overflow-y-auto">
            {renderTabContent()}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default ProfileSettings;