import React from 'react';
import { Check } from "lucide-react";

const PricingCard = ({ 
  plan, 
  price, 
  description, 
  features, 
  bgColor, 
  badge, 
  buttonText = "Start Now",
  isFreePlan,
  onClick 
}) => {
  return (
    <div className="w-full md:w-[460px] h-full overflow-hidden rounded-3xl border border-gray-200 bg-white flex flex-col">
      <div className={`${bgColor} p-6 sm:p-8 text-center`}>
        <h2 className="text-2xl sm:text-3xl font-medium mb-2">{plan}</h2>
        <p className="text-lg sm:text-xl mb-4">{description}</p>
        <div className="inline-flex bg-white h-[27px] px-3 items-center rounded-full border border-black">
          <span className="font-bold whitespace-nowrap">{badge}</span>
        </div>
      </div>

      <div className="p-6 sm:p-8 flex flex-col flex-grow">
        <div className="text-center mb-3">
          <div className="flex items-start justify-center">
            <span className="text-2xl mt-2">$</span>
            <span className="text-6xl font-bold">{price}</span>
            <span className="text-gray-500 ml-1 mt-2 sm:mt-3">/mon</span>
          </div>
        </div>

        <p className="text-center text-lg mb-8">{description}</p>

        <div className="space-y-4 mb-8 flex-grow">
          {features.map((feature, index) => (
            <div key={index} className="flex items-center gap-3">
              <Check className="w-5 h-5 text-gray-400 flex-shrink-0" />
              <span className="text-gray-600">
                {Array.isArray(feature) ? (
                  <>
                    {feature[0]}
                    <span className="group relative inline-flex items-center">
                      {feature[1]}
                      <span className="invisible group-hover:visible absolute -translate-x-1/2 -translate-y-full -top-1 left-1/2 w-64 bg-black text-white text-xs rounded-md py-2 px-3 z-[100]">
                        Facebook Pixel, Google Analytics, Google Tag Manager, Slack
                      </span>
                    </span>
                  </>
                ) : feature}
              </span>
            </div>
          ))}
        </div>

        <button 
          className={`w-full h-14 text-lg rounded-[12px] ${
            isFreePlan ? 'border border-black' : 'bg-black text-white hover:bg-black/90'
          }`}
          onClick={onClick}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default PricingCard; 