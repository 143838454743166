import React from 'react';
import { X } from 'lucide-react';

const UploadProgress = ({ uploads, onCancel }) => {
  if (Object.keys(uploads).length === 0) return null;

  return (
    <div className="fixed bottom-8 right-8 w-80 bg-white rounded-xl shadow-lg border border-gray-200 overflow-hidden">
      <div className="p-3 border-b bg-gray-50 flex justify-between items-center">
        <h4 className="font-medium">Uploading Files</h4>
        <button onClick={onCancel} className="text-gray-500 hover:text-gray-700">
          <X size={18} />
        </button>
      </div>
      <div className="max-h-60 overflow-y-auto">
        {Object.entries(uploads).map(([fileName, progress]) => (
          <div key={fileName} className="p-3 border-b last:border-0">
            <div className="flex justify-between text-sm mb-1">
              <span className="truncate">{fileName}</span>
              <span>{Math.round(progress)}%</span>
            </div>
            <div className="w-full h-1 bg-gray-100 rounded-full overflow-hidden">
              <div 
                className="h-full bg-black transition-all duration-300"
                style={{ width: `${progress}%` }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UploadProgress; 