import React from 'react';
import SurveyPublished from './survey_published.svg';
import AppMain from '../app_main.svg';

const LandingContent = ({ openSignIn }) => {
  return (
    <div className="pt-20">
      {/* Key Benefits Section */}
      <div className="mb-40">
        <div className="text-center mb-20">
          <h2 className="font-['HK_Nova'] font-bold text-3xl sm:text-4xl md:text-5xl mb-8">
            Create forms and surveys people<br />
            love to answer effortlessly.
          </h2>
          
          <div className="max-w-[900px] mx-auto mb-8">
            <img 
              src={SurveyPublished} 
              alt="Survey Example" 
              className="w-full h-auto rounded-[32px] shadow-xl"
              loading="lazy"
            />
          </div>

          <p className="text-xl text-gray-600 max-w-2xl mx-auto leading-relaxed">
            Everything you need to create, distribute, and analyze surveys effectively
          </p>
        </div>

        {/* New Speed Section */}
        <div className="text-center mb-20">
          <h2 className="font-['HK_Nova'] font-bold text-3xl sm:text-4xl md:text-5xl mb-8">
            Built for Speed
          </h2>
          
          <div className="max-w-[900px] mx-auto mb-8">
            <img 
              src={AppMain} 
              alt="PollBolt App Interface" 
              className="w-full h-auto shadow-xl"
              loading="lazy"
            />
          </div>

          <p className="text-xl text-gray-600 max-w-2xl mx-auto leading-relaxed">
            Create, customize, and launch surveys in minutes. PollBolt's intuitive tools save you time, so you can focus on gathering insights.
          </p>
        </div>
      </div>

      {/* Final CTA Section */}
      <div className="mb-20">
        <div className="bg-gradient-to-br from-[#FFFD63]/20 to-white rounded-3xl p-16 sm:p-24 border border-[#FFFD63]/20">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="font-['HK_Nova'] font-bold text-4xl sm:text-5xl mb-6">
              Ready to get started?
            </h2>
            <p className="text-xl text-gray-600 mb-12">
              Join thousands of users who are already creating better surveys with PollBolt
            </p>
            <div className="flex flex-col sm:flex-row gap-6 justify-center items-center">
              <button 
                className="px-10 py-5 text-lg rounded-2xl bg-black text-white hover:bg-gray-800 transition-all duration-300 font-['HK_Nova'] w-full sm:w-auto group relative overflow-hidden shadow-lg hover:shadow-xl"
                onClick={() => openSignIn(true)}
              >
                <span className="relative z-10">Start For Free</span>
                <div className="absolute inset-0 bg-gradient-to-r from-gray-800 to-black transform scale-x-0 group-hover:scale-x-100 transition-transform duration-500 origin-left"></div>
              </button>
              <button 
                className="px-10 py-5 text-lg rounded-2xl border-2 border-black hover:bg-black hover:text-white transition-all duration-300 font-['HK_Nova'] w-full sm:w-auto"
                onClick={() => window.location.href = '/plans'}
              >
                View Pricing
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingContent; 