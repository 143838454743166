import React from 'react';
import { Image } from 'lucide-react';

const EmptyState = () => (
  <div className="text-center py-12">
    <div className="inline-flex items-center justify-center w-16 h-16 bg-gray-100 rounded-full mb-4">
      <Image className="w-8 h-8 text-gray-400" />
    </div>
    <h3 className="text-lg font-medium text-gray-900 mb-2">No media files yet</h3>
    <p className="text-gray-500 mb-4">
      Upload images by dragging & dropping files or clicking the upload area above
    </p>
  </div>
);

export default EmptyState; 