// ui/src/QuestionDesigner.js
import React, { useState } from 'react';
import { PlusCircle, Trash2, ListChecks, CheckSquare, Type, AlignLeft, Hash, Star, ClipboardList, X, Check, Plus } from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';

const QUESTION_TYPES = [
  { value: 'multiple choice', icon: ListChecks, label: 'Multiple Choice' },
  { value: 'checkbox list', icon: CheckSquare, label: 'Checkbox List' },
  { value: 'short text', icon: Type, label: 'Short Answer' },
  { value: 'long text', icon: AlignLeft, label: 'Long Text' },
  { value: 'numeric', icon: Hash, label: 'Numeric' },
  { value: 'star rating', icon: Star, label: 'Star Rating' }
];

const QuestionDesigner = ({ existingQuestion, onQuestionUpdate }) => {
  const handleChange = (field, value) => {
    onQuestionUpdate({
      ...existingQuestion,
      [field]: value
    });
  };

  const handleOptionPaste = (e) => {
    console.log('Paste event triggered'); // Debug log
    const pastedText = e.clipboardData.getData('text');
    console.log('Pasted content:', pastedText); // Debug log

    // Split the pasted text into array by newlines or commas
    const pastedOptions = pastedText
      .split(/[\n,]/)
      .map(opt => opt.trim())
      .filter(opt => opt.length > 0);

    console.log('Processed options:', pastedOptions); // Debug log

    if (pastedOptions.length > 1) {
      e.preventDefault();
      handleChange('options', pastedOptions);
    }
  };

  return (
    <div className="space-y-3">
      {/* Question Text */}
      <div>
        <label className="block text-xs font-medium text-gray-600 mb-1">
          Question Text
        </label>
        <input
          type="text"
          value={existingQuestion.text || ''}
          onChange={(e) => handleChange('text', e.target.value)}
          placeholder="Enter your question"
          className="w-full px-2 py-1.5 text-xs border border-gray-200 rounded-md 
            focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
        />
      </div>

      {/* Question Type */}
      <div>
        <label className="block text-xs font-medium text-gray-600 mb-1">
          Question Type
        </label>
        <select
          value={existingQuestion.type}
          onChange={(e) => handleChange('type', e.target.value)}
          className="w-full px-2 py-1.5 text-xs border border-gray-200 rounded-md 
            focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="multiple choice">Multiple Choice</option>
          <option value="short text">Short Text</option>
          <option value="long text">Long Text</option>
          <option value="numeric">Numeric</option>
          <option value="star rating">Star Rating</option>
        </select>
      </div>

      {/* Options (for multiple choice) */}
      {(existingQuestion.type === 'multiple choice' || existingQuestion.type === 'checkbox list') && (
        <div>
          <div className="mb-3">
            <label className="block text-xs font-medium text-gray-600 mb-2">
              Options
            </label>
            <div className="bg-blue-50 p-2 rounded-md mb-2">
              <span className="text-xs text-blue-600">
                💡 Tip: Paste a list (separated by commas or new lines) to create multiple options at once
              </span>
            </div>
          </div>

          <div className="space-y-2">
            {(existingQuestion.options || []).map((option, index) => (
              <div key={index} className="flex items-center gap-2">
                <input
                  type="text"
                  value={option}
                  onChange={(e) => {
                    const newOptions = [...existingQuestion.options];
                    newOptions[index] = e.target.value;
                    handleChange('options', newOptions);
                  }}
                  onPaste={(e) => {
                    console.log('Paste event on input'); // Debug log
                    handleOptionPaste(e);
                  }}
                  placeholder={`Option ${index + 1}`}
                  className="flex-1 px-2 py-1.5 text-xs border border-gray-200 rounded-md 
                    focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                />
                <button
                  onClick={() => {
                    const newOptions = existingQuestion.options.filter((_, i) => i !== index);
                    handleChange('options', newOptions);
                  }}
                  className="p-1 text-gray-400 hover:text-gray-600 transition-colors"
                >
                  <X size={14} />
                </button>
              </div>
            ))}
            <button
              onClick={() => {
                const newOptions = [...(existingQuestion.options || []), ''];
                handleChange('options', newOptions);
              }}
              className="w-full px-2 py-1.5 text-xs text-gray-600 border border-gray-200 
                rounded-md hover:bg-gray-50 transition-colors flex items-center justify-center gap-1"
            >
              <Plus size={14} />
              Add Option
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionDesigner;
