import React, { useState } from 'react';

export const Tooltip = ({ content, children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setPosition({
      x: rect.left + rect.width / 2,
      y: rect.top - 5
    });
    setIsVisible(true);
  };

  return (
    <div
      className="relative inline-block"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      {isVisible && (
        <div
          className="absolute z-50 px-2 py-1 text-sm text-white bg-black rounded-lg whitespace-nowrap transform -translate-x-1/2 -translate-y-full"
          style={{
            left: position.x,
            top: position.y - 5,
          }}
        >
          {content}
          <div
            className="absolute left-1/2 transform -translate-x-1/2 translate-y-1/2 bottom-0 w-2 h-2 bg-black rotate-45"
          />
        </div>
      )}
    </div>
  );
}; 