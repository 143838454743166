import React, { useState } from 'react';
import PageWrapper from './components/PageWrapper';
import Lottie from 'lottie-react';
import heroAnimation from './app_main.json';
import { 
  Zap, 
  TrendingUp, 
  Target,
  Users,
  LineChart,
  Briefcase,
  Calendar,
  GraduationCap,
  Box,
  TestTube,
  Heart,
  Building
} from 'lucide-react';
import LandingContent from './components/LandingContent';
import MainNavigation from './components/MainNavigation';
import Footer from './components/Footer';

const LandingPage = ({ openSignIn }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <div className="min-h-screen w-full">
      {/* Yellow Hero Section */}
      <div className="bg-[#FFFD63]">
        <div className="max-w-[1920px] mx-auto px-4">
          <MainNavigation 
            openSignIn={openSignIn}
            isMobileMenuOpen={isMobileMenuOpen}
            setIsMobileMenuOpen={setIsMobileMenuOpen}
          />
          <div className="max-w-[1600px] mx-auto text-center">
            {/* Hero Content */}
            <div className="pb-20 pt-16">
              {/* Main Title */}
              <h1 className="font-['HK_Nova'] font-bold text-3xl sm:text-4xl md:text-6xl leading-tight tracking-[-2px] mb-6">
                Get answers faster<br />
                than ever
              </h1>

              {/* Subtitle */}
              <p className="font-['HK_Nova'] text-lg sm:text-xl text-black max-w-2xl mx-auto leading-relaxed mb-8">
                Nobody likes a boring form—and nobody wants to fill one out.<br />
                With PollBolt, you can create dynamic, engaging forms in no time and get responses just as fast.
              </p>

              {/* CTA Buttons */}
              <div className="flex flex-col sm:flex-row gap-6 justify-center items-center mb-12">
                <button 
                  className="px-10 py-5 text-lg rounded-2xl bg-black text-white hover:bg-gray-800 transition-all duration-300 font-['HK_Nova'] w-full sm:w-auto group relative overflow-hidden shadow-lg hover:shadow-xl inline-flex items-center justify-center gap-3"
                  onClick={() => openSignIn(true)}
                >
                  <svg className="w-5 h-5 relative z-10" viewBox="0 0 24 24">
                    <path
                      fill="#4285F4"
                      d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                    />
                    <path
                      fill="#34A853"
                      d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                    />
                    <path
                      fill="#FBBC05"
                      d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                    />
                    <path
                      fill="#EA4335"
                      d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                    />
                    <path fill="none" d="M1 1h22v22H1z" />
                  </svg>
                  <span className="relative z-10">Sign up with Google</span>
                  <div className="absolute inset-0 bg-gradient-to-r from-gray-800 to-black transform scale-x-0 group-hover:scale-x-100 transition-transform duration-500 origin-left"></div>
                </button>
                <button 
                  className="px-10 py-5 text-lg rounded-2xl bg-white hover:bg-white/80 transition-all duration-300 font-['HK_Nova'] w-full sm:w-auto shadow-md hover:shadow-lg group"
                  onClick={() => window.location.href = '/features'}
                >
                  See all features
                  <span className="inline-block ml-2 transform group-hover:translate-x-1 transition-transform">→</span>
                </button>
              </div>

              {/* Hero Image - replaced with Lottie animation */}
              <div className="relative max-w-[1200px] mx-auto">
                <div className="absolute inset-0 bg-gradient-to-b from-[#FFFD63]/0 via-gray-50/5 to-gray-100/10 rounded-[32px] transform -rotate-1"></div>
                <Lottie 
                  animationData={heroAnimation}
                  className="max-w-full h-auto mx-auto rounded-[32px] shadow-xl"
                />
                <p className="text-black text-sm mt-4 font-medium">
                  ✓ No credit card required &nbsp;&nbsp; ✓ No time limit on Free plan
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* White Content Section */}
      <div className="bg-white">
        <div className="max-w-[1600px] mx-auto px-4 py-20">
          <LandingContent openSignIn={openSignIn} />
        </div>

        {/* Footer */}
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
