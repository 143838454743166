import React from 'react';
import { Check, AlertCircle } from 'lucide-react';

const Message = ({ type, text }) => {
  if (!text) return null;

  return (
    <div
      className={`flex items-center space-x-2 p-4 rounded-lg ${
        type === 'success' ? 'bg-green-50 text-green-700' : 'bg-red-50 text-red-700'
      }`}
    >
      {type === 'success' ? (
        <Check className="w-5 h-5" />
      ) : (
        <AlertCircle className="w-5 h-5" />
      )}
      <span className="text-sm font-medium">{text}</span>
    </div>
  );
};

export default Message; 