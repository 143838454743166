import { useState, useCallback } from 'react';
import { getAuthInstance } from '../../../firebase';
import { updateProfile, updateEmail } from 'firebase/auth';

export const useProfileForm = (user) => {
  const [formData, setFormData] = useState({
    displayName: user?.displayName || '',
    email: user?.email || '',
    newPassword: '',
    confirmPassword: '',
    currentPassword: ''
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });

  const updateFormField = useCallback((field, value) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  }, []);

  const handleProfileUpdate = async () => {
    try {
      setLoading(true);
      const auth = getAuthInstance();
      
      // Update display name if changed
      if (formData.displayName !== user.displayName) {
        await updateProfile(auth.currentUser, {
          displayName: formData.displayName
        });
      }

      // Update email if changed
      if (formData.email !== user.email) {
        await updateEmail(auth.currentUser, formData.email);
      }

      // Handle password update if new password is provided
      if (formData.newPassword) {
        if (formData.newPassword !== formData.confirmPassword) {
          throw new Error('New passwords do not match');
        }
        // Add password update logic here
      }

      setMessage({ type: 'success', text: 'Profile updated successfully!' });
    } catch (error) {
      setMessage({ type: 'error', text: error.message });
    } finally {
      setLoading(false);
    }
  };

  return {
    formData,
    loading,
    message,
    setMessage,
    updateFormField,
    handleProfileUpdate
  };
}; 