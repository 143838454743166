import React, { useState } from 'react';
import { Bell, Mail, MessageSquare, Star } from 'lucide-react';
import Message from './Message';

const NotificationToggle = ({ label, description, icon: Icon, checked, onChange }) => (
  <div className="flex items-center justify-between p-4 border border-gray-200 rounded-xl group hover:border-gray-300 transition-colors">
    <div className="flex items-start space-x-4">
      <div className="p-2 bg-gray-100 rounded-lg group-hover:bg-gray-200 transition-colors">
        <Icon className="w-5 h-5 text-gray-600" />
      </div>
      <div>
        <p className="font-medium text-gray-900">{label}</p>
        <p className="text-sm text-gray-500">{description}</p>
      </div>
    </div>
    <label className="relative inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        className="sr-only peer"
        checked={checked}
        onChange={onChange}
      />
      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-gray-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-black"></div>
    </label>
  </div>
);

const NotificationSection = ({ title, description, children }) => (
  <div className="space-y-4">
    <div>
      <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
      <p className="text-sm text-gray-500">{description}</p>
    </div>
    <div className="space-y-3">
      {children}
    </div>
  </div>
);

const NotificationsTab = () => {
  const [message, setMessage] = useState({ type: '', text: '' });
  const [preferences, setPreferences] = useState({
    emailNotifications: true,
    surveyResponses: true,
    marketingEmails: false,
    productUpdates: true,
    teamNotifications: false,
    securityAlerts: true
  });

  const handleToggle = (key) => {
    setPreferences(prev => {
      const newPreferences = { ...prev, [key]: !prev[key] };
      
      // Simulate saving to backend
      setMessage({ 
        type: 'success', 
        text: 'Notification preferences updated successfully!' 
      });
      
      // Clear message after 3 seconds
      setTimeout(() => {
        setMessage({ type: '', text: '' });
      }, 3000);
      
      return newPreferences;
    });
  };

  return (
    <div className="space-y-8 animate-fadeIn">
      {message.text && <Message type={message.type} text={message.text} />}

      <NotificationSection
        title="Email Notifications"
        description="Manage how you receive email notifications"
      >
        <NotificationToggle
          label="Survey Responses"
          description="Get notified when someone responds to your survey"
          icon={MessageSquare}
          checked={preferences.surveyResponses}
          onChange={() => handleToggle('surveyResponses')}
        />
        <NotificationToggle
          label="Marketing Updates"
          description="Receive news and promotional offers"
          icon={Mail}
          checked={preferences.marketingEmails}
          onChange={() => handleToggle('marketingEmails')}
        />
        <NotificationToggle
          label="Product Updates"
          description="Learn about new features and improvements"
          icon={Star}
          checked={preferences.productUpdates}
          onChange={() => handleToggle('productUpdates')}
        />
      </NotificationSection>

      <NotificationSection
        title="System Notifications"
        description="Control your system notification preferences"
      >
        <NotificationToggle
          label="Team Activity"
          description="Notifications about your team members' actions"
          icon={Bell}
          checked={preferences.teamNotifications}
          onChange={() => handleToggle('teamNotifications')}
        />
        <NotificationToggle
          label="Security Alerts"
          description="Get notified about important security updates"
          icon={Bell}
          checked={preferences.securityAlerts}
          onChange={() => handleToggle('securityAlerts')}
        />
      </NotificationSection>

      <div className="pt-4">
        <button
          onClick={() => {
            setMessage({ 
              type: 'success', 
              text: 'All notification preferences have been saved!' 
            });
            setTimeout(() => setMessage({ type: '', text: '' }), 3000);
          }}
          className="px-6 py-3 bg-black text-white rounded-xl hover:bg-gray-900 transition-colors"
        >
          Save Preferences
        </button>
      </div>
    </div>
  );
};

export default NotificationsTab; 