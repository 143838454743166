import React, { useEffect, createContext, useState, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import './App.css';
import SurveyDesigner from './ui/src/SurveyDesigner';
import SurveyList from './ui/src/SurveyList';
import NavBar from './ui/src/NavBar';
import SignIn from './ui/src/SignIn';
import LandingPage from './ui/src/LandingPage';
import { useAuthState } from 'react-firebase-hooks/auth';
import SurveyViewer from './ui/src/SurveyViewer';
import PricingPage from './ui/src/PricingPage';
import SurveyOverview from './ui/src/SurveyOverview';
import SurveyResponses from './ui/src/SurveyResponses';
import SurveySettings from './ui/src/SurveySettings';
import LoadingAnimation from './ui/src/components/LoadingAnimation';
import { getAuthInstance } from './ui/src/firebase';
import ProfileSettings from './ui/src/pages/ProfileSettings';
import Templates from './ui/src/Templates';
import SurveyIntegrate from './ui/src/pages/SurveyIntegrate';
import FeaturesPage from './ui/src/pages/FeaturesPage';
import PlansPage from './ui/src/pages/PlansPage';

export const UserContext = createContext(null);

const SurveyAnalytics = lazy(() => import('./ui/src/SurveyAnalytics'));
const SurveyLogic = lazy(() => import('./ui/src/pages/SurveyLogic'));
const LogicConfigurator = lazy(() => import('./ui/src/LogicConfigurator'));
const QuestionRenderer = lazy(() => import('./ui/src/QuestionRenderer'));

function App() {
  const auth = getAuthInstance();
  const [user, loading, error] = useAuthState(auth);
  const [showSignIn, setShowSignIn] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    setInitializing(false);
  }, []);

  const openSignIn = (signup = false) => {
    setIsSignUp(signup);
    setShowSignIn(true);
  };
  
  const closeSignIn = () => {
    setShowSignIn(false);
    setIsSignUp(false);
  };

  // Protected route handler
  const ProtectedRoute = ({ children }) => {
    const location = useLocation();
    const isSurveyPath = location.pathname.startsWith('/s/');
    
    if (!user && !isSurveyPath) {
      return <Navigate to="/" replace />;
    }
    
    return children;
  };

  if (loading || initializing) {
    return <LoadingAnimation />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="w-full min-h-screen pt-14">
      <UserContext.Provider value={user}>
        <Router>
          <div className="min-h-screen bg-white">
            {user && !window.location.pathname.startsWith('/s/') && <NavBar openSignIn={openSignIn} />}
            <main className="w-full">
              <Routes>
                {/* Public routes */}
                <Route 
                  path="/" 
                  element={user ? <Navigate to="/surveys" /> : <LandingPage openSignIn={openSignIn} />} 
                />
                <Route path="/features" element={<FeaturesPage openSignIn={openSignIn} />} />
                <Route path="/plans" element={<PlansPage openSignIn={openSignIn} />} />
                <Route path="/s/:surveyId" element={<SurveyViewer />} />

                {/* Protected routes */}
                <Route 
                  path="/surveys" 
                  element={
                    <ProtectedRoute>
                      <SurveyList />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/survey/:surveyId" 
                  element={
                    <ProtectedRoute>
                      <SurveyDesigner />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/survey/:surveyId/analytics" 
                  element={
                    <ProtectedRoute>
                      <Suspense fallback={<LoadingAnimation />}>
                        <SurveyAnalytics surveyId={window.location.pathname.split('/')[2]} />
                      </Suspense>
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/pricing" 
                  element={
                    <ProtectedRoute>
                      <PricingPage openSignIn={openSignIn} />
                    </ProtectedRoute>
                  } 
                />
                <Route path="/survey/:id/overview" element={<SurveyOverview />} />
                <Route path="/survey/:id/responses" element={<SurveyResponses />} />
                <Route path="/survey/:id/settings" element={<SurveySettings />} />
                <Route 
                  path="/profile" 
                  element={
                    <ProtectedRoute>
                      <ProfileSettings />
                    </ProtectedRoute>
                  } 
                />
                <Route path="/survey/:surveyId/integrate" element={<SurveyIntegrate />} />
                <Route 
                  path="/survey/:surveyId/logic" 
                  element={
                    <Suspense fallback={<LoadingAnimation />}>
                      <SurveyLogic />
                    </Suspense>
                  }
                />
              </Routes>
            </main>
            {showSignIn && <SignIn onClose={closeSignIn} isSignUp={isSignUp} />}
          </div>
        </Router>
      </UserContext.Provider>
    </div>
  );
}

export default App;
