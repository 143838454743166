import React from 'react';
import { CreditCard } from 'lucide-react';

const SubscriptionStatus = ({ subscription }) => {
  const getPlanBackgroundColor = (planName) => {
    switch (planName) {
      case 'Pro':
        return 'bg-[#FFFD63]';
      case 'Growth':
        return 'bg-[#D3FBF1]';
      case 'Free':
      default:
        return 'bg-[#FFFEF2]';
    }
  };

  return (
    <div className={`rounded-lg ${getPlanBackgroundColor(subscription?.name)}`}>
      <div className="flex items-start space-x-4">
        <div className="p-2 bg-white rounded-lg">
          <CreditCard className="w-6 h-6 text-gray-600" />
        </div>
        <div className="flex-1">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-semibold text-gray-900">{subscription?.name} Plan</h3>
            <span className="px-3 py-1 bg-blue-100 text-blue-700 text-sm font-medium rounded-full">
              Active
            </span>
          </div>
          <p className="text-gray-600 mt-1">
            You are currently on the {subscription?.name} plan{subscription?.name === 'Free' ? ' with basic features' : ''}.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionStatus;