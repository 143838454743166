// ui/src/SurveyDesigner.js
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import QuestionDesigner from './QuestionDesigner';
import QuestionRenderer from './QuestionRenderer';
import { Zap, Check, Share2, Copy, Link2, X, Smartphone, Monitor, Pencil, ChevronLeft, ChevronRight, Plus, Play, ListChecks, TextCursor, AlignLeft, Hash, Star, MoreVertical, Trash2, Eye, Image, AlignCenter, AlignRight, CheckSquare, ArrowLeftToLine, MoveHorizontal, ArrowRightToLine, Layout, LayoutTemplate, LayoutSidebar, LayoutSidebarReverse, PanelLeft, PanelRight, Upload, ImagePlus, Library } from 'lucide-react';
import { 
  saveSurvey, 
  getSurvey, 
  updateSurveyBackground, 
  getMediaFiles 
} from './serverComm';
import debounce from 'lodash/debounce';
import { ChromePicker } from 'react-color';
import SurveyViewer from './SurveyViewer';
import { uploadLogo, getPreviousLogos, uploadBackground, getPreviousBackgrounds, getStockPhotos, deleteMediaFile } from './utils/mediaUpload';
import { getAuthInstance, getStorageInstance } from './firebase';
import SurveyDesignerSkeleton from './components/SurveyDesignerSkeleton';
import { createPortal } from 'react-dom';

const SurveyDesigner = () => {
  const auth = getAuthInstance();
  const storage = getStorageInstance();

  const defaultStyles = {
    fontFamily: 'Inter',
    submitButtonColor: '#4F46E5',
    backgroundColor: '#FFFFFF',
    questionTextColor: '#000000',
    customSubmitText: '',
    useCustomSubmitText: false,
    backgroundAlignment: 'cover',
    backgroundImage: '',
    logo: {
      url: '',
      size: 'md',
      alignment: 'left'
    }
  };

  const { surveyId } = useParams();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [saveStatus, setSaveStatus] = useState('idle'); // 'idle', 'saving', 'saved'
  const [surveyTitle, setSurveyTitle] = useState('');
  const [showShareModal, setShowShareModal] = useState(false);
  const [copyStatus, setCopyStatus] = useState('idle'); // 'idle' | 'copied'
  const [previewMode, setPreviewMode] = useState('desktop'); // 'desktop' | 'mobile'
  const [titleInputRef, setTitleInputRef] = useState(null);
  const [activeTab, setActiveTab] = useState('questions'); // 'questions' | 'style' | 'advanced'
  const [surveyStyles, setSurveyStyles] = useState(defaultStyles);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [dragOverIndex, setDragOverIndex] = useState(null);
  const [showBgColorPicker, setShowBgColorPicker] = useState(false);
  const [currentPreviewQuestion, setCurrentPreviewQuestion] = useState(0);
  const [selectedQuestionForEdit, setSelectedQuestionForEdit] = useState(null);
  const [showLogicConfig, setShowLogicConfig] = useState(false);
  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const [showMenu, setShowMenu] = useState(null);
  const [isTypeDropdownOpen, setIsTypeDropdownOpen] = useState(false);
  const [hoverRating, setHoverRating] = useState(0);
  const [isFontDropdownOpen, setIsFontDropdownOpen] = useState(false);
  const [activeEditorTab, setActiveEditorTab] = useState('edit'); // 'edit' | 'style'
  const [showTextColorPicker, setShowTextColorPicker] = useState(false);
  const [logoUrl, setLogoUrl] = useState(null);
  const [logoSize, setLogoSize] = useState('md');
  const [logoAlignment, setLogoAlignment] = useState('left');
  const [showMediaModal, setShowMediaModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [previousLogos, setPreviousLogos] = useState([]);
  const [isLoadingLogos, setIsLoadingLogos] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [backgroundImage, setBackgroundImage] = useState(null); // New state for background image
  const [showBackgroundImageModal, setShowBackgroundImageModal] = useState(false);
  const [backgroundAlignment, setBackgroundAlignment] = useState('center'); // 'left', 'center', 'right'
  const [activeBackgroundTab, setActiveBackgroundTab] = useState('stock');// 'stock' | 'upload' | 'library'
  const [stockPhotos, setStockPhotos] = useState([]);

  const questionTypeIcons = {
    'multiple choice': ListChecks,
    'checkbox list': CheckSquare,
    'short text': TextCursor,
    'long text': AlignLeft,
    'numeric': Hash,
    'star rating': Star
  };

  const questionTypes = [
    { value: 'multiple choice', label: 'Multiple Choice', icon: ListChecks, description: 'Let respondents choose from predefined options' },
    { value: 'checkbox list', label: 'Checkbox List', icon: CheckSquare, description: 'Allow respondents to select multiple options' },
    { value: 'short text', label: 'Short Text', icon: TextCursor, description: 'Collect brief text responses' },
    { value: 'long text', label: 'Long Text', icon: AlignLeft, description: 'Gather detailed text responses' },
    { value: 'numeric', label: 'Numeric', icon: Hash, description: 'Collect numerical data' },
    { value: 'star rating', label: 'Star Rating', icon: Star, description: 'Get feedback on a rating scale' }
  ];

  const fonts = [
    { name: 'Inter', value: 'Inter' },
    { name: 'Roboto', value: 'Roboto' },
    { name: 'Open Sans', value: 'Open Sans' },
    { name: 'Lato', value: 'Lato' },
    { name: 'Poppins', value: 'Poppins' },
    { name: 'Montserrat', value: 'Montserrat' },
    { name: 'Playfair Display', value: 'Playfair Display' },
    { name: 'DM Sans', value: 'DM Sans' },
    { name: 'Source Sans Pro', value: 'Source Sans Pro' },
    { name: 'Manrope', value: 'Manrope' },
    { name: 'Work Sans', value: 'Work Sans' },
    { name: 'Space Grotesk', value: 'Space Grotesk' },
    { name: 'Plus Jakarta Sans', value: 'Plus Jakarta Sans' },
    { name: 'Outfit', value: 'Outfit' },
    { name: 'Sora', value: 'Sora' }
  ];

  useEffect(() => {
    async function loadExistingSurvey() {
      if (!surveyId) {
        setIsLoading(false);
        return;
      }

      try {
        const surveyData = await getSurvey(surveyId);
        setSurveyTitle(surveyData.title);
        setQuestions(surveyData.questions);
        
        // Ensure all style properties are properly initialized
        const initialStyles = {
          ...defaultStyles,
          ...surveyData.styles,
          backgroundAlignment: surveyData.styles?.backgroundAlignment || 'cover',
          backgroundImage: surveyData.styles?.backgroundImage || '',
          logo: {
            url: surveyData.styles?.logo?.url || '',
            size: surveyData.styles?.logo?.size || 'md',
            alignment: surveyData.styles?.logo?.alignment || 'left'
          }
        };

        console.log('Setting initial styles:', initialStyles);
        setSurveyStyles(initialStyles);
        
        if (surveyData.questions && surveyData.questions.length > 0) {
          setSelectedQuestionForEdit(surveyData.questions[0]);
          setCurrentPreviewQuestion(0);
          setActiveEditorTab('edit');
        }
      } catch (error) {
        console.error('Error loading survey:', error);
      } finally {
        setIsLoading(false);
      }
    }

    loadExistingSurvey();
  }, [surveyId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isTypeDropdownOpen && !event.target.closest('.question-type-dropdown')) {
        setIsTypeDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isTypeDropdownOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showColorPicker && !event.target.closest('.button-color-picker')) {
        setShowColorPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [showColorPicker]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showBgColorPicker && !event.target.closest('.button-color-picker')) {
        setShowBgColorPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [showBgColorPicker]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showMenu && !event.target.closest('.group')) {
        setShowMenu(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [showMenu]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isFontDropdownOpen && !event.target.closest('.font-dropdown')) {
        setIsFontDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isFontDropdownOpen]);

  useEffect(() => {
    const handleOptionPaste = (e) => {
      // Check if the paste target is a radio input option field
      if (e.target.classList.contains('radio-option-input')) {
        e.preventDefault();
        const pastedText = e.clipboardData.getData('text');
        
        // Split the pasted text into array by newlines or commas
        const pastedOptions = pastedText
          .split(/[\n,]/)  // Split by newline or comma
          .map(opt => opt.trim())
          .filter(opt => opt.length > 0);

        console.log('Pasted options:', pastedOptions); // For debugging

        if (pastedOptions.length > 1 && selectedQuestionForEdit) {
          // Update the question with new options
          const updatedQuestions = questions.map(q => {
            if (q.id === selectedQuestionForEdit.id) {
              return {
                ...q,
                options: pastedOptions
              };
            }
            return q;
          });

          // Update states
          setQuestions(updatedQuestions);
          const updatedQuestion = updatedQuestions.find(q => q.id === selectedQuestionForEdit.id);
          setSelectedQuestionForEdit(updatedQuestion);

          // Save changes
          handleSaveSurvey({
            title: surveyTitle,
            questions: updatedQuestions,
            styles: surveyStyles
          });
        }
      }
    };

    // Add paste event listener to document
    document.addEventListener('paste', handleOptionPaste);

    // Cleanup
    return () => {
      document.removeEventListener('paste', handleOptionPaste);
    };
  }, [selectedQuestionForEdit, questions]); // Add any other dependencies you need

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showTextColorPicker && !event.target.closest('.text-color-picker')) {
        setShowTextColorPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [showTextColorPicker]);

  useEffect(() => {
    const loadStockPhotos = async () => {
      const photos = await getStockPhotos();
      setStockPhotos(photos);
    };
    
    if (showBackgroundImageModal) {
      loadStockPhotos();
    }
  }, [showBackgroundImageModal]);

  const addQuestion = (question) => {
    const newQuestion = {
      id: uuidv4(),
      text: question.text || '',
      type: question.type || 'multiple choice',
      options: Array.isArray(question.options) ? question.options : [],
      ...question
    };

    setQuestions(prevQuestions => {
      const currentQuestions = Array.isArray(prevQuestions) ? prevQuestions : [];
      return [...currentQuestions, newQuestion];
    });
  };

  const deleteQuestion = (id) => {
    setQuestions(prevQuestions => {
      const updatedQuestions = prevQuestions.filter(q => q.id !== id);
      
      // If the deleted question was selected, select another question
      if (selectedQuestionForEdit?.id === id) {
        // If there are remaining questions, select the previous one or the first one
        if (updatedQuestions.length > 0) {
          const currentIndex = prevQuestions.findIndex(q => q.id === id);
          const newIndex = Math.max(0, currentIndex - 1);
          setSelectedQuestionForEdit(updatedQuestions[newIndex]);
          setCurrentPreviewQuestion(newIndex);
        } else {
          // If no questions remain, clear the selection
          setSelectedQuestionForEdit(null);
          setCurrentPreviewQuestion(0);
        }
      }

      // Save the survey after deleting the question
      handleSaveSurvey({
        title: surveyTitle,
        questions: updatedQuestions,
        styles: surveyStyles
      });
      
      return updatedQuestions;
    });
  };

  const onDragStart = () => {
    console.log('Drag started');
  };  

  const onDragEnd = (result) => {
    console.log('Drag ended', result);
    if (!result.destination) {
      console.log('No destination');
      return;
    }

    const items = Array.from(questions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    console.log('Reordering questions:', items);
    setQuestions(items);

    // Save the new order
    handleSaveSurvey({
      title: surveyTitle,
      questions: items,
      styles: surveyStyles
    });
  };

  const handleSaveSurvey = async (data) => {
    try {
      setSaveStatus('saving');
      
      // Create the complete survey data object
      const surveyData = {
        surveyId: surveyId,
        title: data.title || surveyTitle,
        questions: data.questions || questions,
        styles: {
          ...surveyStyles,
          ...data.styles,
          fontFamily: data.styles?.fontFamily || surveyStyles.fontFamily,
          submitButtonColor: data.styles?.submitButtonColor || surveyStyles.submitButtonColor,
          backgroundColor: data.styles?.backgroundColor || surveyStyles.backgroundColor,
          backgroundImage: data.styles?.backgroundImage || surveyStyles.backgroundImage,
          backgroundAlignment: data.styles?.backgroundAlignment || surveyStyles.backgroundAlignment,
          logo: surveyStyles.logo || {}
        }
      };

      console.log('Saving survey with data:', surveyData);
      
      // Save the survey
      await saveSurvey(surveyData);
      
      // Fetch the updated survey to refresh the state
      const updatedSurvey = await getSurvey(surveyId);
      
      // Update all relevant states
      setQuestions(updatedSurvey.questions);
      setSurveyTitle(updatedSurvey.title);
      setSurveyStyles(updatedSurvey.styles);
      
      setSaveStatus('saved');
      setTimeout(() => setSaveStatus('idle'), 2000);
    } catch (error) {
      console.error('Error saving survey:', error);
      setSaveStatus('error');
    }
  };

  const handleViewAnalytics = () => {
    navigate(`/survey/${surveyId}/analytics`);
  };

  const getSaveButtonContent = () => {
    switch (saveStatus) {
      case 'saving':
        return (
          <>
            Saving... <Zap size={20} className="ml-2 animate-spin text-black fill-black" strokeWidth={2} />
          </>
        );
      case 'saved':
        return (
          <>
            Saved! <Check size={20} className="ml-2" strokeWidth={2} />
          </>
        );
      default:
        return (
          <>
            Publish <Zap size={20} className="ml-2 text-black fill-black" strokeWidth={2} />
          </>
        );
    }
  };

  const handleQuestionUpdate = (updatedQuestion) => {
    console.log('Updating question:', updatedQuestion); // Add this debug log
    
    const updatedQuestions = questions.map(q => 
      q.id === updatedQuestion.id ? updatedQuestion : q
    );
    setQuestions(updatedQuestions);
    setSelectedQuestionForEdit(updatedQuestion);
    
    // Save the updated questions
    handleSaveSurvey({
      title: surveyTitle,
      questions: updatedQuestions,
      styles: surveyStyles
    });
  };

  const handleShare = () => {
    setShowShareModal(true);
  };

  const handleCopyLink = async () => {
    const surveyUrl = `${window.location.origin}/s/${surveyId}`;
    try {
      await navigator.clipboard.writeText(surveyUrl);
      setCopyStatus('copied');
      setTimeout(() => setCopyStatus('idle'), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const debouncedSave = useCallback(
    debounce(async (newTitle, questions, styles) => {
      setSaveStatus('saving');
      try {
        console.log('Saving styles (debounced):', styles);
        
        await saveSurvey(surveyId, newTitle, questions, {
          fontFamily: styles.fontFamily,
          submitButtonColor: styles.submitButtonColor,
          backgroundColor: styles.backgroundColor,
          useCustomSubmitText: styles.useCustomSubmitText,
          customSubmitText: styles.customSubmitText
        });
        setSaveStatus('saved');
        setTimeout(() => setSaveStatus('idle'), 2000);
      } catch (error) {
        console.error('Error saving survey:', error);
        setSaveStatus('idle');
        alert('Failed to save survey. Please try again.');
      }
    }, 1000),
    [surveyId]
  );

  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    setSurveyTitle(newTitle);
    debouncedSave(newTitle, questions, surveyStyles);
  };

  const handlePencilClick = () => {
    if (titleInputRef) {
      titleInputRef.focus();
      titleInputRef.select();
    }
  };

  const handleStyleChange = async (type, value) => {
    try {
      let updatedStyles;
      
      if (type === 'logo') {
        // For logo updates, preserve the URL while updating size or alignment
        updatedStyles = {
          ...surveyStyles,
          logo: {
            ...surveyStyles.logo, // Keep existing logo properties
            ...value // Update with new properties
          }
        };
      } else {
        // Handle other style changes
        updatedStyles = {
          ...surveyStyles,
          [type]: value
        };
      }

      // Update local state immediately
      setSurveyStyles(updatedStyles);

      // Save to server
      const saveData = {
        surveyId: surveyId,
        title: surveyTitle,
        questions: questions,
        styles: updatedStyles
      };

      console.log('Saving updated styles:', saveData);
      await saveSurvey(saveData);
      
      // Optional: Fetch the latest data to confirm changes
      const updatedSurvey = await getSurvey(surveyId);
      setSurveyStyles(updatedSurvey.styles);
      
    } catch (error) {
      console.error('Error updating styles:', error);
      // Revert local state if save failed
      const currentSurvey = await getSurvey(surveyId);
      setSurveyStyles(currentSurvey.styles);
      alert('Failed to update style. Please try again.');
    }
  };

  const handlePreviewPoll = () => {
    const previewUrl = `${window.location.origin}/s/${surveyId}`;
    window.open(previewUrl, '_blank');
  };

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setDragOverIndex(index);
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    
    const newQuestions = [...questions];
    const draggedQuestion = newQuestions[draggedIndex];
    
    // Remove dragged item
    newQuestions.splice(draggedIndex, 1);
    // Add it at new position
    newQuestions.splice(index, 0, draggedQuestion);
    
    // Update states
    setQuestions(newQuestions);
    setDraggedIndex(null);
    setDragOverIndex(null);
    setCurrentPreviewQuestion(index);
    setSelectedQuestionForEdit(draggedQuestion);

    // Save the new order
    handleSaveSurvey({
      title: surveyTitle,
      questions: newQuestions,
      styles: surveyStyles
    });
  };

  // Handler for selecting a question to edit
  const handleQuestionSelect = (question, index) => {
    setSelectedQuestionForEdit(question);
    setCurrentPreviewQuestion(index); // Sync preview with selection
  };

  // Handler for preview navigation
  const handlePreviewNavigation = (index) => {
    setCurrentPreviewQuestion(index);
    setSelectedQuestionForEdit(questions[index]); // Sync editor with preview
  };

  const handleAddQuestion = (type) => {
    const newQuestion = {
      id: uuidv4(),
      text: 'New Question',
      type: type,
      options: type === 'checkbox list' ? ['Option 1', 'Option 2'] : [], // Ensure options array exists
      answer: type === 'checkbox list' ? [] : ''
    };
    
    setQuestions(prevQuestions => {
      const updatedQuestions = [...prevQuestions, newQuestion];
      setCurrentPreviewQuestion(updatedQuestions.length - 1);
      
      handleSaveSurvey({
        title: surveyTitle,
        questions: updatedQuestions,
        styles: surveyStyles
      });
      
      return updatedQuestions;
    });
    
    setSelectedQuestionForEdit(newQuestion);
    setShowQuestionModal(false);
  };

  const handleDuplicateQuestion = (questionToDuplicate) => {
    const duplicatedQuestion = {
      ...questionToDuplicate,
      id: uuidv4() // Generate new ID for duplicate
    };
    
    setQuestions(prevQuestions => {
      const newQuestions = [...prevQuestions];
      // Find index of original question and insert duplicate after it
      const index = newQuestions.findIndex(q => q.id === questionToDuplicate.id);
      newQuestions.splice(index + 1, 0, duplicatedQuestion);
      
      // Save the survey with the duplicated question
      handleSaveSurvey({
        title: surveyTitle,
        questions: newQuestions,
        styles: surveyStyles
      });
      
      return newQuestions;
    });
  };

  const handleOptionPaste = (e, questionId) => {
    const pastedText = e.clipboardData.getData('text');
    console.log('Pasted text:', pastedText); // Debug log

    // Split the pasted text into array by newlines or commas
    const pastedOptions = pastedText
      .split(/[\n,]/)
      .map(opt => opt.trim())
      .filter(opt => opt.length > 0);

    console.log('Processed options:', pastedOptions); // Debug log

    if (pastedOptions.length > 1) {
      e.preventDefault(); // Prevent default paste only if we detect a list

      // Update the question with new options
      const updatedQuestions = questions.map(q => {
        if (q.id === questionId) {
          return {
            ...q,
            options: pastedOptions
          };
        }
        return q;
      });

      // Update states
      setQuestions(updatedQuestions);
      
      // Find and set the updated question
      const updatedQuestion = updatedQuestions.find(q => q.id === questionId);
      setSelectedQuestionForEdit(updatedQuestion);

      // Save changes
      handleSaveSurvey({
        title: surveyTitle,
        questions: updatedQuestions,
        styles: surveyStyles
      });
    }
  };

  // Add some CSS classes
  const styles = {
    dragging: 'opacity-50 border-2 border-dashed border-blue-500',
    dragOver: 'border-t-2 border-blue-500'
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setIsUploading(true);
    try {
      const downloadURL = await uploadLogo(file, surveyId);
      
      // Create new styles object with all existing styles plus the logo
      const updatedStyles = {
        ...surveyStyles,
        logo: {
          url: downloadURL,
          size: 'md',
          alignment: 'left'
        }
      };

      // Update local state
      setSurveyStyles(updatedStyles);

      // Save to server
      const saveData = {
        id: surveyId,
        surveyId: surveyId,
        title: surveyTitle,
        questions: questions,
        styles: updatedStyles
      };

      console.log('Saving survey data:', saveData); // Debug log
      await saveSurvey(saveData);
      setShowMediaModal(false);
    } catch (error) {
      console.error('Error uploading logo:', error);
      alert('Failed to upload logo. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  const loadPreviousLogos = async () => {
    setIsLoadingLogos(true);
    try {
      const logos = await getPreviousLogos(surveyId);
      setPreviousLogos(logos);
    } catch (error) {
      console.error('Error loading previous logos:', error);
    } finally {
      setIsLoadingLogos(false);
    }
  };

  useEffect(() => {
    if (showMediaModal) {
      loadPreviousLogos();
    }
  }, [showMediaModal]);

  const handleLogoSelect = async (logo) => {
    try {
      const updatedStyles = {
        ...surveyStyles,
        logo: {
          url: logo.url,
          size: 'md',
          alignment: 'left'
        }
      };

      setSurveyStyles(updatedStyles);

      const saveData = {
        id: surveyId,
        surveyId: surveyId,
        title: surveyTitle,
        questions: questions,
        styles: updatedStyles
      };

      await saveSurvey(saveData);
      setShowMediaModal(false);
    } catch (error) {
      console.error('Error selecting logo:', error);
      alert('Failed to select logo. Please try again.');
    }
  };

  // Add this handler for the custom submit text toggle
  const handleCustomSubmitTextToggle = async (enabled) => {
    try {
      const updatedStyles = {
        ...surveyStyles,
        useCustomSubmitText: enabled,
        // If disabling, reset the custom text
        customSubmitText: enabled ? (surveyStyles.customSubmitText || '') : ''
      };

      setSurveyStyles(updatedStyles);
      
      await saveSurvey({
        surveyId: surveyId,
        title: surveyTitle,
        questions: questions,
        styles: updatedStyles
      });
    } catch (error) {
      console.error('Error updating custom submit text toggle:', error);
      // Revert on error
      setSurveyStyles(prevStyles => ({...prevStyles}));
    }
  };

  // Add this handler for the custom submit text input
  const handleCustomSubmitTextChange = async (text) => {
    try {
      const updatedStyles = {
        ...surveyStyles,
        customSubmitText: text,
        useCustomSubmitText: true // Ensure it's enabled when text is entered
      };

      setSurveyStyles(updatedStyles);
      
      await saveSurvey({
        surveyId: surveyId,
        title: surveyTitle,
        questions: questions,
        styles: updatedStyles
      });
    } catch (error) {
      console.error('Error updating custom submit text:', error);
      // Revert on error
      setSurveyStyles(prevStyles => ({...prevStyles}));
    }
  };

  // Add a debounced save function for logo updates
  const debouncedLogoUpdate = useCallback(
    debounce(async (updatedStyles) => {
      try {
        await saveSurvey({
          surveyId: surveyId,
          title: surveyTitle,
          questions: questions,
          styles: updatedStyles
        });
      } catch (error) {
        console.error('Error saving logo updates:', error);
      }
    }, 500),
    [surveyId, surveyTitle, questions]
  );

  // Update the logo handling functions
  const handleLogoSizeChange = (newSize) => {
    const updatedStyles = {
      ...surveyStyles,
      logo: {
        ...surveyStyles.logo,
        size: newSize
      }
    };
    
    // Update local state immediately
    setSurveyStyles(updatedStyles);
    
    // Debounce the server update
    debouncedLogoUpdate(updatedStyles);
  };

  const handleLogoAlignmentChange = (newAlignment) => {
    const updatedStyles = {
      ...surveyStyles,
      logo: {
        ...surveyStyles.logo,
        alignment: newAlignment
      }
    };
    
    // Update local state immediately
    setSurveyStyles(updatedStyles);
    
    // Debounce the server update
    debouncedLogoUpdate(updatedStyles);
  };

  // Add this new function for background image updates
  const debouncedBackgroundUpdate = useCallback(
    debounce(async (updatedStyles) => {
      try {
        await saveSurvey({
          surveyId: surveyId,
          title: surveyTitle,
          questions: questions,
          styles: updatedStyles
        });
      } catch (error) {
        console.error('Error saving background updates:', error);
      }
    }, 500),
    [surveyId, surveyTitle, questions]
  );

  // Update the handleBackgroundImageSelect function
  const handleBackgroundImageSelect = async (image) => {
    try {
      const updatedStyles = {
        ...surveyStyles,
        backgroundImage: image.url,
        backgroundAlignment: 'cover' // Set default alignment to 'cover'
      };

      // Update local state
      setSurveyStyles(updatedStyles);

      // Save to server
      await saveSurvey({
        surveyId: surveyId,
        title: surveyTitle,
        questions: questions,
        styles: updatedStyles
      });

      setShowBackgroundImageModal(false);
    } catch (error) {
      console.error('Error selecting background image:', error);
      alert('Failed to select background image. Please try again.');
    }
  };

  // Update the handleBackgroundImageUpload function
  const handleBackgroundImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setIsUploading(true);
    try {
      // Upload the file and get the result
      const uploadResult = await uploadLogo(file, surveyId);
      
      // Use the url from the upload result
      const updatedStyles = {
        ...surveyStyles,
        backgroundImage: uploadResult.url,
        backgroundAlignment: 'cover' // Set default alignment to 'cover'
      };

      // Update local state first
      setSurveyStyles(updatedStyles);

      try {
        // Save to server
        await saveSurvey({
          surveyId: surveyId,
          title: surveyTitle,
          questions: questions,
          styles: updatedStyles
        });
        
        // Reload the images
        await loadMediaFiles();
        setShowBackgroundImageModal(false);
      } catch (saveError) {
        console.error('Error saving survey:', saveError);
        // If save fails, revert the styles
        setSurveyStyles(prevStyles => ({...prevStyles}));
        alert('Failed to update survey with new background. Please try again.');
      }
    } catch (error) {
      console.error('Error uploading background image:', error);
      alert('Failed to upload image. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  // Add this function to load media files
  const loadMediaFiles = async () => {
    setIsLoadingLogos(true);
    try {
      const files = await getMediaFiles();
      setPreviousLogos(files);
    } catch (error) {
      console.error('Error loading media files:', error);
    } finally {
      setIsLoadingLogos(false);
    }
  };

  // Update the useEffect for background image modal
  useEffect(() => {
    if (showBackgroundImageModal || showMediaModal) {
      loadMediaFiles();
    }
  }, [showBackgroundImageModal, showMediaModal]);

  // Update the handleBackgroundAlignmentChange function
  const handleBackgroundAlignmentChange = async (alignment) => {
    try {
      console.log('Changing background alignment to:', alignment);
      
      // Update local state immediately
      const updatedStyles = {
        ...surveyStyles,
        backgroundAlignment: alignment
      };
      
      setSurveyStyles(updatedStyles);

      // Save to server with complete data
      const saveData = {
        surveyId: surveyId,
        title: surveyTitle,
        questions: questions,
        styles: updatedStyles
      };

      // Use saveSurvey directly instead of handleSaveSurvey
      await saveSurvey(saveData);

      // Verify the save by fetching updated data
      const updatedSurvey = await getSurvey(surveyId);
      setSurveyStyles(updatedSurvey.styles);

      console.log('Background alignment updated successfully:', alignment);
    } catch (error) {
      console.error('Error updating background alignment:', error);
      // Revert on error
      setSurveyStyles(prevStyles => ({...prevStyles}));
    }
  };

  // Import the new function
  const handleDeleteMedia = async (fileUrl) => {
    try {
      // First update UI and database to remove references
      if (surveyStyles.backgroundImage === fileUrl || surveyStyles.logo?.url === fileUrl) {
        const updatedStyles = {
          ...surveyStyles,
          backgroundImage: surveyStyles.backgroundImage === fileUrl ? '' : surveyStyles.backgroundImage,
          logo: surveyStyles.logo?.url === fileUrl ? { url: '', size: 'md', alignment: 'left' } : surveyStyles.logo
        };
        
        setSurveyStyles(updatedStyles);
        await saveSurvey({
          surveyId: surveyId,
          title: surveyTitle,
          questions: questions,
          styles: updatedStyles
        });
      }

      // Delete the file
      await deleteMediaFile(fileUrl);
      
      // Force a complete refresh using getMediaFiles
      const freshFiles = await getMediaFiles();
      setPreviousLogos(freshFiles);
      
    } catch (error) {
      console.error('Error updating survey:', error);
      // Still try to get a fresh list
      try {
        const freshFiles = await getMediaFiles();
        setPreviousLogos(freshFiles);
      } catch (refreshError) {
        console.error('Failed to refresh media list:', refreshError);
      }
    }
  };

  if (isLoading) {
    return <SurveyDesignerSkeleton />;
  }

  return (
    <div className="fixed inset-0 overflow-hidden">
      <div className="h-full">
        {/* Main Content - 3-column layout */}
        <div className="flex h-full bg-gray-50 pt-12">
          {/* Left Panel - Questions List (13.3%) */}
          <div className="w-[13.3%] min-w-[260px] overflow-y-auto border border-gray-200 bg-white rounded-xl shadow-sm m-4">
            <div className="space-y-2 p-4">
              {questions.map((q, index) => (
                <div
                  key={q.id}
                  draggable
                  onDragStart={() => handleDragStart(index)}
                  onDragOver={(e) => handleDragOver(e, index)}
                  onDragEnd={() => {
                    setDraggedIndex(null);
                    setDragOverIndex(null);
                  }}
                  onDrop={(e) => handleDrop(e, index)}
                  onClick={() => {
                    setSelectedQuestionForEdit(q);
                    setCurrentPreviewQuestion(index);
                  }}
                  className={`
                    group relative rounded-lg p-4 transition-all duration-200 border border-gray-200
                    ${dragOverIndex === index ? 'before:border-t-2 before:border-blue-500' : ''}
                    ${selectedQuestionForEdit?.id === q.id 
                      ? 'bg-blue-50 ring-2 ring-blue-500 border-transparent' 
                      : 'hover:border-blue-200 hover:bg-blue-50/50'
                    }
                    cursor-move
                  `}
                >
                  {/* Question number badge */}
                  <div className={`
                    absolute -left-2 -top-2 w-6 h-6 rounded-full 
                    flex items-center justify-center text-sm font-medium
                    ${selectedQuestionForEdit?.id === q.id
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-100 text-gray-500'
                    }
                  `}>
                    {index + 1}
                  </div>

                  {/* Question type icon */}
                  <div className="flex items-center gap-3 mb-2">
                    {React.createElement(questionTypeIcons[q.type], {
                      size: 16,
                      className: `${
                        selectedQuestionForEdit?.id === q.id
                          ? 'text-blue-500'
                          : 'text-gray-400'
                      }`
                    })}
                    <span className={`text-sm font-medium ${
                      selectedQuestionForEdit?.id === q.id
                        ? 'text-blue-700'
                        : 'text-gray-600'
                    }`}>
                      {q.type.charAt(0).toUpperCase() + q.type.slice(1)}
                    </span>
                  </div>

                  {/* Question text */}
                  <div className={`
                    text-sm pl-1
                    ${selectedQuestionForEdit?.id === q.id
                      ? 'text-blue-700'
                      : 'text-gray-500'
                    }
                  `}>
                    {q.text || 'Click to edit question'}
                  </div>

                  {/* Quick Menu Button - Show on hover */}
                  <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowMenu(showMenu === q.id ? null : q.id);
                      }}
                      className="p-1 hover:bg-gray-100 rounded-md transition-colors"
                    >
                      <MoreVertical size={16} className="text-gray-500" />
                    </button>

                    {/* Quick Menu Dropdown */}
                    {showMenu === q.id && (
                      <div 
                        className="absolute right-0 mt-1 w-48 bg-white rounded-lg shadow-lg border border-gray-200 py-1 z-50"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <button
                          onClick={() => {
                            handleDuplicateQuestion(q);
                            setShowMenu(null);
                          }}
                          className="w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 flex items-center gap-2"
                        >
                          <Copy size={16} />
                          Duplicate Question
                        </button>
                        <button
                          onClick={() => {
                            deleteQuestion(q.id);
                            setShowMenu(null);
                          }}
                          className="w-full px-4 py-2 text-sm text-red-600 hover:bg-red-50 flex items-center gap-2"
                        >
                          <Trash2 size={16} />
                          Delete Question
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Middle Panel - Preview (73.4%) */}
          <div className="w-[73.4%] bg-gray-50 overflow-hidden px-4 pt-4">
            <div className="h-full">
              {/* Preview Controls */}
              <div className="sticky top-0 bg-gray-50 z-10">
                <div className="bg-white rounded-xl shadow-sm border border-gray-200">
                  <div className="flex items-center justify-between px-3 py-2">
                    {/* Left side - Add Question */}
                    <button
                      onClick={() => setShowQuestionModal(true)}
                      className="flex items-center gap-2 px-3 py-1.5 text-sm font-medium text-blue-600 bg-white border border-gray-200 rounded-md hover:bg-gray-50 transition-colors"
                    >
                      <Plus size={16} />
                      Add Question
                    </button>

                    {/* Right side - All controls */}
                    <div className="flex items-center gap-2">
                      {/* Device Toggle */}
                      <div className="flex items-center bg-gray-100 rounded-md p-1">
                        <button
                          onClick={() => setPreviewMode('desktop')}
                          className={`p-1.5 rounded ${
                            previewMode === 'desktop'
                              ? 'bg-white shadow-sm'
                              : 'text-gray-500 hover:text-gray-700'
                          }`}
                        >
                          <Monitor size={16} />
                        </button>
                        <button
                          onClick={() => setPreviewMode('mobile')}
                          className={`p-1.5 rounded ${
                            previewMode === 'mobile'
                              ? 'bg-white shadow-sm'
                              : 'text-gray-500 hover:text-gray-700'
                          }`}
                        >
                          <Smartphone size={16} />
                        </button>
                      </div>

                      {/* Share Button */}
                      <button
                        onClick={handleShare}
                        className="flex items-center gap-2 px-3 py-1.5 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors"
                      >
                        <Share2 size={16} />
                        Share
                      </button>

                      {/* Preview Button */}
                      <button
                        onClick={() => window.open(`/s/${surveyId}`, '_blank')}
                        className="flex items-center gap-2 px-3 py-1.5 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors"
                      >
                        <Eye size={16} />
                        Preview
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Preview Container */}
              <div className="h-full w-full relative flex items-start justify-center overflow-hidden pb-10">
                <div 
                  className={`h-full overflow-hidden border border-gray-200 flex flex-col relative ${
                    previewMode === 'mobile' 
                      ? 'w-[375px]' 
                      : 'w-full max-w-[900px] lg:max-w-[1200px] xl:max-w-[1400px] 2xl:max-w-[1600px] mx-auto' // Increased widths for larger screens
                  } max-h-[calc(100vh-220px)] bg-white shadow-sm mt-6`}
                  style={{ 
                    backgroundColor: surveyStyles?.backgroundColor || '#FFFFFF',
                    fontFamily: surveyStyles?.fontFamily || 'Inter',
                  }}
                >
                  {/* Background Image Container */}
                  {surveyStyles?.backgroundImage && surveyStyles?.backgroundAlignment !== 'cover' && (
                    <>
                      {/* Mobile Preview: Always Cover */}
                      {previewMode === 'mobile' && (
                        <div className="absolute inset-0">
                          <img
                            src={surveyStyles.backgroundImage}
                            alt="Background"
                            className="w-full h-full object-cover"
                          />
                        </div>
                      )}
                      
                      {/* Desktop Preview: Split Layout */}
                      {previewMode === 'desktop' && (
                        <div 
                          className={`absolute top-0 h-full w-1/2 overflow-hidden ${
                            surveyStyles?.backgroundAlignment === 'left' ? 'left-0' : 'right-0'
                          }`}
                        >
                          <img
                            src={surveyStyles.backgroundImage}
                            alt="Background"
                            className="w-full h-full object-cover"
                          />
                        </div>
                      )}
                    </>
                  )}

                  {/* Full Cover Background (when explicitly set to cover) */}
                  {surveyStyles?.backgroundImage && surveyStyles?.backgroundAlignment === 'cover' && (
                    <div 
                      className="absolute inset-0"
                      style={{
                        backgroundImage: `url(${surveyStyles.backgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                      }}
                    />
                  )}

                  {/* Logo - Fixed Position Outside Frame */}
                  {surveyStyles?.logo?.url && (
                    <div className="absolute top-12 w-full z-20"> {/* Changed top-8 to top-12 */}
                      <img
                        src={surveyStyles.logo.url}
                        alt="Survey Logo"
                        className={`
                          ${surveyStyles.logo.size === 'sm' ? 'max-h-[30px]' : 
                            surveyStyles.logo.size === 'md' ? 'max-h-[50px]' : 'max-h-[70px]'}
                          ${surveyStyles.logo.alignment === 'left' ? 'ml-4 mr-auto' : 
                            surveyStyles.logo.alignment === 'center' ? 'mx-auto' : 'ml-auto mr-4'}
                          object-contain
                        `}
                      />
                    </div>
                  )}

                  {/* Content Container */}
                  <div className="relative z-10 flex-1 flex flex-col">
                    {/* Header Section with progress bar */}
                    <div className="w-full">
                      <div className="w-full bg-gray-200 h-2">
                        <div 
                          className="bg-blue-500 h-2 transition-all duration-300"
                          style={{ 
                            width: `${((currentPreviewQuestion + 1) / questions.length) * 100}%`
                          }}
                        />
                      </div>
                    </div>

                    {/* Question Content Frame */}
                    <div className={`relative flex-1 flex ${
                      surveyStyles?.backgroundImage && 
                      surveyStyles?.backgroundAlignment !== 'cover' && 
                      previewMode !== 'mobile' // Add this condition
                        ? 'w-1/2 ' + (surveyStyles?.backgroundAlignment === 'right' ? 'mr-auto' : 'ml-auto')
                        : 'w-full'
                    }`}>
                      {/* Question Content */}
                      <div className={`flex-1 flex flex-col items-center justify-start w-full max-w-2xl mx-auto ${
                        previewMode === 'mobile' 
                          ? 'mt-32' // Mobile margin
                          : 'mt-24 lg:mt-32 xl:mt-20' // Responsive desktop margins
                      }`}> 
                        <div className={`w-full px-4 ${
                          previewMode === 'mobile' 
                            ? 'max-w-[375px]'
                            : 'px-6 md:px-8 lg:px-12'
                        } ${
                          surveyStyles?.backgroundImage && 
                          surveyStyles?.backgroundAlignment !== 'cover' &&
                          previewMode !== 'mobile'
                            ? 'items-start'
                            : 'items-center'
                        }`}>
                          <div className={`w-full flex flex-col ${
                            surveyStyles?.backgroundImage && 
                            surveyStyles?.backgroundAlignment !== 'cover' &&
                            previewMode !== 'mobile'
                              ? 'items-start'
                              : 'items-center'
                          }`}>
                            <QuestionRenderer
                              question={questions[currentPreviewQuestion]}
                              isDesignerPreview={true}
                              onQuestionUpdate={handleQuestionUpdate}
                              onNavigate={(direction) => {
                                if (direction === 'next' && currentPreviewQuestion < questions.length - 1) {
                                  const nextIndex = currentPreviewQuestion + 1;
                                  setCurrentPreviewQuestion(nextIndex);
                                  setSelectedQuestionForEdit(questions[nextIndex]);
                                } else if (direction === 'previous' && currentPreviewQuestion > 0) {
                                  const prevIndex = currentPreviewQuestion - 1;
                                  setCurrentPreviewQuestion(prevIndex);
                                  setSelectedQuestionForEdit(questions[prevIndex]);
                                }
                              }}
                              isLastQuestion={currentPreviewQuestion === questions.length - 1}
                              currentQuestionIndex={currentPreviewQuestion}
                              totalQuestions={questions.length}
                              showProgress={false}
                              styles={{
                                ...surveyStyles,
                                alignment: previewMode === 'mobile' 
                                  ? 'center' 
                                  : (surveyStyles?.backgroundImage && surveyStyles?.backgroundAlignment !== 'cover'
                                    ? 'left'
                                    : 'center'),
                                maxWidth: previewMode === 'mobile' ? '100%' : '100%',
                                containerWidth: previewMode === 'mobile' ? 'w-full' : 'max-w-xl',
                                questionSpacing: 'mb-5',
                                // Adjusted for shorter height
                                answerFontSize: 'text-sm',
                                answerPadding: 'py-1.5 px-3', // Reduced vertical padding from py-2
                                answerSpacing: 'space-y-1',
                                radioSize: 'w-5 h-5',
                                checkboxSize: 'w-5 h-5',
                                questionFontSize: 'text-lg',
                                inputHeight: 'h-8', // Reduced from h-9
                                textareaHeight: 'h-24',
                                answerContainerPadding: 'py-2', // Reduced from py-3
                                answerLineHeight: 'leading-snug',
                                answerMargin: 'my-0.5',
                                compact: true
                              }}
                              questionNumber={currentPreviewQuestion + 1}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right Panel - Question Editor (13.3%) */}
          <div className="w-[13.3%] min-w-[260px] overflow-y-auto border border-gray-200 bg-white rounded-xl shadow-sm m-4">
            {/* Tabs - Sticky */}
            <div className="sticky top-0 bg-white z-20">
              <div className="border-b border-gray-200">
                <nav className="flex -mb-px px-2 pt-2">
                  {['Edit', 'Style'].map((tab) => (
                    <button
                      key={tab}
                      onClick={() => setActiveEditorTab(tab.toLowerCase())}
                      className={`px-3 py-2 text-xs font-medium transition-colors
                        ${activeEditorTab === tab.toLowerCase()
                          ? 'border-b-2 border-blue-500 text-blue-600'
                          : 'border-b-2 border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                        }
                      `}
                    >
                      {tab}
                    </button>
                  ))}
                </nav>
              </div>
            </div>

            {/* Tab Content */}
            <div className="p-4">
              {activeEditorTab === 'edit' && selectedQuestionForEdit && (
                <div className="space-y-3">
                  {/* Question Type */}
                  <div className="question-type-dropdown">
                    <label className="block text-xs font-medium text-gray-600 mb-1">
                      Question Type
                    </label>
                    <div className="relative">
                      <button
                        type="button"
                        onClick={() => setIsTypeDropdownOpen(!isTypeDropdownOpen)}
                        className="w-full pl-9 pr-4 py-2 text-sm border border-gray-200 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 bg-white flex items-center justify-between"
                      >
                        <div className="flex items-center gap-2">
                          <div className="absolute left-2.5">
                            {React.createElement(
                              questionTypes.find(t => t.value === selectedQuestionForEdit.type)?.icon || ListChecks,
                              { size: 16, className: "text-gray-500" }
                            )}
                          </div>
                          <span className="ml-7">
                            {questionTypes.find(t => t.value === selectedQuestionForEdit.type)?.label}
                          </span>
                        </div>
                        <ChevronRight size={16} className={`text-gray-400 transition-transform duration-200 ${isTypeDropdownOpen ? 'rotate-[270deg]' : 'rotate-90'}`} />
                      </button>

                      {/* Dropdown menu */}
                      {isTypeDropdownOpen && (
                        <div className="absolute z-10 mt-1 w-full bg-white border border-gray-200 rounded-lg shadow-lg py-1">
                          {questionTypes.map(({ value, label, icon: Icon, description }) => (
                            <button
                              key={value}
                              onClick={() => {
                                // Create updated question
                                const updatedQuestion = {
                                  ...selectedQuestionForEdit,
                                  type: value,
                                  options: value === 'multiple choice' ? ['Option 1', 'Option 2'] : []
                                };

                                // Update questions array
                                const updatedQuestions = questions.map(q => 
                                  q.id === selectedQuestionForEdit.id ? updatedQuestion : q
                                );
                                
                                // Update all necessary states
                                setQuestions(updatedQuestions);
                                setSelectedQuestionForEdit(updatedQuestion);
                                setIsTypeDropdownOpen(false);

                                // Trigger save
                                handleSaveSurvey({
                                  title: surveyTitle,
                                  questions: updatedQuestions,
                                  styles: surveyStyles
                                });
                              }}
                              className={`w-full px-3 py-2 text-left flex items-center gap-3 hover:bg-gray-50 ${
                                selectedQuestionForEdit.type === value ? 'bg-blue-50' : ''
                              }`}
                            >
                              <Icon size={16} className={`flex-shrink-0 ${
                                selectedQuestionForEdit.type === value ? 'text-blue-500' : 'text-gray-500'
                              }`} />
                              <div>
                                <div className={`text-sm ${
                                  selectedQuestionForEdit.type === value ? 'text-blue-600 font-medium' : 'text-gray-700'
                                }`}>
                                  {label}
                                </div>
                                <div className="text-xs text-gray-500">{description}</div>
                              </div>
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Question-specific style options */}
                  {selectedQuestionForEdit && (
                    <div className="mt-4 pt-4 border-t border-gray-200">
                      {/* Your existing question-specific style options */}
                    </div>
                  )}
                </div>
              )}

              {activeEditorTab === 'style' && (
                <div className="space-y-3">
                  {/* Global Style Settings */}
                  <div>
                    <label className="block text-xs font-medium text-gray-600 mb-1">
                      Font Family
                    </label>
                    <div className="relative font-dropdown">
                      <button
                        onClick={() => setIsFontDropdownOpen(!isFontDropdownOpen)}
                        className="w-full px-3 py-2 text-sm border border-gray-200 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 bg-white flex items-center justify-between"
                        style={{ fontFamily: surveyStyles.fontFamily }}
                      >
                        <span>{surveyStyles.fontFamily}</span>
                        <ChevronRight 
                          size={16} 
                          className={`text-gray-400 transition-transform duration-200 ${
                            isFontDropdownOpen ? 'rotate-[270deg]' : 'rotate-90'
                          }`} 
                        />
                      </button>

                      {isFontDropdownOpen && (
                        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg py-1 max-h-[280px] overflow-y-auto">
                          {fonts.map((font) => (
                            <button
                              key={font.value}
                              onClick={() => {
                                handleStyleChange('fontFamily', font.value);
                                setIsFontDropdownOpen(false);
                              }}
                              className={`w-full px-3 py-2 text-left hover:bg-gray-50 ${
                                surveyStyles.fontFamily === font.value ? 'bg-blue-50' : ''
                              }`}
                            >
                              <span 
                                className="text-sm font-medium"
                                style={{ fontFamily: font.value }}
                              >
                                {font.name}
                              </span>
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Background Color */}
                  <div>
                    <div className="flex items-center justify-between">
                      <label className="text-xs font-medium text-gray-600">
                        Background Color
                      </label>
                      <button
                        onClick={() => setShowBgColorPicker(!showBgColorPicker)}
                        className="flex items-center gap-1 bg-white border border-gray-200 rounded-md px-2 py-1 hover:border-gray-300"
                      >
                        <div
                          className="w-5 h-5 rounded border border-gray-200"
                          style={{ backgroundColor: surveyStyles.backgroundColor }}
                        />
                        <ChevronRight 
                          size={14} 
                          className={`text-gray-400 transition-transform duration-200 ${
                            showBgColorPicker ? '-rotate-90' : 'rotate-90'
                          }`}
                        />
                      </button>
                    </div>
                    {showBgColorPicker && (
                      <div className="absolute right-0 mt-2 z-10">
                        <div className="button-color-picker p-2 bg-white rounded-lg shadow-lg">
                          <ChromePicker
                            color={surveyStyles.backgroundColor}
                            onChange={(color) => handleStyleChange('backgroundColor', color.hex)}
                          />
                          <button
                            onClick={() => setShowBgColorPicker(false)}
                            className="w-full mt-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 text-sm font-medium flex items-center justify-center gap-2"
                          >
                            <Check size={16} />
                            Done
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Question Text Color */}
                  <div>
                    <div className="flex items-center justify-between">
                      <label className="text-xs font-medium text-gray-600">
                        Question Text Color
                      </label>
                      <button
                        onClick={() => setShowTextColorPicker(!showTextColorPicker)}
                        className="flex items-center gap-1 bg-white border border-gray-200 rounded-md px-2 py-1 hover:border-gray-300"
                      >
                        <div
                          className="w-5 h-5 rounded border border-gray-200"
                          style={{ backgroundColor: surveyStyles.questionTextColor }}
                        />
                        <ChevronRight 
                          size={14} 
                          className={`text-gray-400 transition-transform duration-200 ${
                            showTextColorPicker ? '-rotate-90' : 'rotate-90'
                          }`}
                        />
                      </button>
                    </div>
                    {showTextColorPicker && (
                      <div className="absolute right-0 mt-2 z-10">
                        <div className="text-color-picker p-2 bg-white rounded-lg shadow-lg">
                          <ChromePicker
                            color={surveyStyles.questionTextColor}
                            onChange={(color) => handleStyleChange('questionTextColor', color.hex)}
                          />
                          <button
                            onClick={() => setShowTextColorPicker(false)}
                            className="w-full mt-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 text-sm font-medium flex items-center justify-center gap-2"
                          >
                            <Check size={16} />
                            Done
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Button Color */}
                  <div>
                    <div className="flex items-center justify-between">
                      <label className="text-xs font-medium text-gray-600">
                        Button Color
                      </label>
                      <button
                        onClick={() => setShowColorPicker(!showColorPicker)}
                        className="flex items-center gap-1 bg-white border border-gray-200 rounded-md px-2 py-1 hover:border-gray-300"
                      >
                        <div
                          className="w-5 h-5 rounded border border-gray-200"
                          style={{ backgroundColor: surveyStyles.submitButtonColor }}
                        />
                        <ChevronRight 
                          size={14} 
                          className={`text-gray-400 transition-transform duration-200 ${
                            showColorPicker ? '-rotate-90' : 'rotate-90'
                          }`}
                        />
                      </button>
                    </div>
                    {showColorPicker && (
                      <div className="absolute right-0 mt-2 z-10">
                        <div className="button-color-picker p-2 bg-white rounded-lg shadow-lg">
                          <ChromePicker
                            color={surveyStyles.submitButtonColor}
                            onChange={(color) => handleStyleChange('submitButtonColor', color.hex)}
                          />
                          <button
                            onClick={() => setShowColorPicker(false)}
                            className="w-full mt-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 text-sm font-medium flex items-center justify-center gap-2"
                          >
                            <Check size={16} />
                            Done
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Move Brand Logo, Logo Size, and Logo Alignment here */}
                  <div>
                    {/* Logo Controls Group */}
                    <div className="space-y-3">
                      {/* Logo Header and Controls */}
                      <div className="flex items-center justify-between mt-8">
                        <span className="text-xs font-medium text-gray-600">Brand Logo</span>
                        <div className="flex items-center gap-1">
                          <button
                            onClick={() => setShowMediaModal(true)}
                            className="p-1 hover:bg-gray-100 rounded-md transition-colors"
                            title="Change Logo"
                          >
                            <Image size={14} className="text-gray-500" />
                          </button>
                          <button
                            onClick={async () => {
                              try {
                                const updatedStyles = {
                                  ...surveyStyles,
                                  logo: {
                                    url: '',
                                    size: 'md',
                                    alignment: 'left'
                                  }
                                };
                                setSurveyStyles(updatedStyles);
                                await saveSurvey({
                                  id: surveyId,
                                  surveyId: surveyId,
                                  title: surveyTitle,
                                  questions: questions,
                                  styles: updatedStyles
                                });
                                setLogoUrl('');
                                setLogoSize('md');
                                setLogoAlignment('left');
                              } catch (error) {
                                console.error('Error removing logo:', error);
                                alert('Failed to remove logo. Please try again.');
                              }
                            }}
                            className="p-1 hover:bg-gray-100 rounded-md transition-colors"
                            title="Remove Logo"
                          >
                            <Trash2 size={14} className="text-gray-500" />
                          </button>
                        </div>
                      </div>

                      {/* Logo Preview Box */}
                      <div 
                        className="border rounded-lg p-3 bg-gray-50 cursor-pointer hover:border-blue-500 transition-colors"
                        onClick={() => setShowMediaModal(true)}
                      >
                        {surveyStyles?.logo?.url ? (
                          <div className="relative aspect-video flex items-center justify-center bg-white rounded-md overflow-hidden group">
                            <img 
                              src={surveyStyles.logo.url} 
                              alt="Logo Preview"
                              className="max-w-full max-h-full object-contain p-2"
                            />
                            {/* Hover Overlay */}
                            <div className="absolute inset-0 bg-black/25 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                              <div className="px-4 py-2 bg-white/90 rounded-md shadow-sm">
                                <span className="text-gray-700 text-sm font-medium">Change Logo</span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="aspect-video flex items-center justify-center bg-white rounded-md border border-dashed border-gray-200">
                            <div className="text-xs text-gray-400 text-center">
                              <Image size={20} className="mx-auto mb-1 opacity-40" />
                              Click to add logo
                            </div>
                          </div>
                        )}
                      </div>

                      {/* Logo Size */}
                      <div className="mt-3">
                        <label className="block text-xs font-medium text-gray-600 mb-2">
                          Logo Size
                        </label>
                        <div className="flex gap-1 p-1 bg-gray-50 rounded-lg">
                          {['sm', 'md', 'lg'].map((size) => (
                            <button
                              key={size}
                              onClick={() => handleLogoSizeChange(size)}
                              className={`flex-1 p-2 rounded transition-all ${
                                surveyStyles.logo?.size === size
                                  ? 'bg-white shadow-sm text-blue-600'
                                  : 'text-gray-500 hover:text-gray-700'
                              }`}
                              title={size.toUpperCase()}
                            >
                              <Image 
                                size={size === 'sm' ? 14 : size === 'md' ? 18 : 22} 
                                className="mx-auto" 
                              />
                            </button>
                          ))}
                        </div>
                      </div>

                      {/* Logo Alignment */}
                      <div className="space-y-2">
                        <label className="block text-xs font-medium text-gray-600 mb-2">
                          Logo Alignment
                        </label>
                        <div className="flex gap-1 p-1 bg-gray-50 rounded-lg">
                          {[
                            { value: 'left', Icon: AlignLeft },
                            { value: 'center', Icon: AlignCenter },
                            { value: 'right', Icon: AlignRight }
                          ].map(({ value, Icon }) => (
                            <button
                              key={value}
                              onClick={() => handleLogoAlignmentChange(value)}
                              className={`flex-1 p-2 rounded transition-all ${
                                surveyStyles.logo?.alignment === value
                                  ? 'bg-white shadow-sm text-blue-600'
                                  : 'text-gray-500 hover:text-gray-700'
                              }`}
                              title={`Align ${value}`}
                            >
                              <Icon size={14} className="mx-auto" />
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Background Image Section with separator */}
                  <div className="mt-8 pt-8 border-t border-gray-200"> {/* Added separator */}
                    <div className="flex items-center justify-between mb-1">
                      <span className="text-xs font-medium text-gray-600">Background Image</span>
                      {surveyStyles.backgroundImage && (
                        <button
                          onClick={() => {
                            const updatedStyles = {
                              ...surveyStyles,
                              backgroundImage: null
                            };
                            setSurveyStyles(updatedStyles);
                            handleStyleChange('backgroundImage', null);
                          }}
                          className="p-1 hover:bg-gray-100 rounded-md transition-colors"
                          title="Remove Background"
                        >
                          <Trash2 size={14} className="text-gray-500" />
                        </button>
                      )}
                    </div>
                    
                    {/* Background Image Preview/Upload Box */}
                    <div 
                      className="border rounded-lg p-3 bg-gray-50 cursor-pointer hover:border-blue-500 transition-colors"
                      onClick={() => setShowBackgroundImageModal(true)}
                    >
                      {surveyStyles.backgroundImage ? (
                        <div className="relative aspect-video flex items-center justify-center bg-white rounded-md overflow-hidden group">
                          <img 
                            src={surveyStyles.backgroundImage} 
                            alt="Background Preview"
                            className="w-full h-full object-cover"
                          />
                          {/* Hover Overlay */}
                          <div className="absolute inset-0 bg-black/25 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center rounded-lg">
                            <button className="text-white">
                              <Check size={20} />
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="aspect-video flex items-center justify-center bg-white rounded-md border border-dashed border-gray-200">
                          <div className="text-xs text-gray-400 text-center">
                            <Image size={20} className="mx-auto mb-1 opacity-40" />
                            Click to add background image
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Background Image Layout */}
                    {surveyStyles.backgroundImage && (
                      <div className="mt-3">
                        <label className="block text-xs font-medium text-gray-600 mb-2">
                          Background Layout
                        </label>
                        <div className="flex gap-1 p-1 bg-gray-50 rounded-lg">
                          {[
                            { value: 'cover', Icon: Layout, label: 'Full Cover' },
                            { value: 'left', Icon: PanelLeft, label: 'Image Left' },
                            { value: 'right', Icon: PanelRight, label: 'Image Right' }
                          ].map(({ value, Icon, label }) => {
                            const currentAlignment = surveyStyles.backgroundAlignment || 'cover';
                            const isSelected = currentAlignment === value;
                            
                            return (
                              <button
                                key={value}
                                onClick={() => handleBackgroundAlignmentChange(value)}
                                className={`flex-1 p-2 rounded transition-all ${
                                  isSelected
                                    ? 'bg-white shadow-sm text-blue-600'
                                    : 'text-gray-500 hover:text-gray-700'
                                }`}
                                title={label}
                              >
                                <Icon size={16} className="mx-auto" />
                              </button>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Question-specific style options */}
                  {selectedQuestionForEdit && (
                    <div className="mt-4 pt-4 border-t border-gray-200">
                      {/* Your existing question-specific style options */}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Share Modal */}
      {showShareModal && createPortal(
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center" style={{ zIndex: 99999 }}>
          <div className="bg-white rounded-xl shadow-xl max-w-md w-full p-6 m-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold text-gray-900">Share Survey</h3>
              <button
                onClick={() => setShowShareModal(false)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X size={20} />
              </button>
            </div>

            <div className="space-y-4">
              {/* Survey Link */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Survey Link
                </label>
                <div className="flex items-center gap-2">
                  <input
                    type="text"
                    readOnly
                    value={`${window.location.origin}/s/${surveyId}`}
                    className="flex-1 p-2 text-sm bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  />
                  <button
                    onClick={handleCopyLink}
                    className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
                  >
                    {copyStatus === 'copied' ? (
                      <>
                        <Check size={16} className="text-green-500" />
                        Copied!
                      </>
                    ) : (
                      <>
                        <Copy size={16} />
                        Copy
                      </>
                    )}
                  </button>
                </div>
              </div>

              {/* Share Options */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Share via
                </label>
                <div className="grid grid-cols-2 gap-3">
                  {[
                    { name: 'Email', icon: 'mail', color: 'bg-blue-50 text-blue-600' },
                    { name: 'WhatsApp', icon: 'message-circle', color: 'bg-green-50 text-green-600' },
                  ].map((option) => (
                    <button
                      key={option.name}
                      className={`flex items-center justify-center gap-2 p-3 rounded-lg border border-gray-200 hover:bg-gray-50 transition-colors ${option.color}`}
                    >
                      <span className="text-sm font-medium">{option.name}</span>
                    </button>
                  ))}
                </div>
              </div>

              {/* Privacy Settings */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Privacy Settings
                </label>
                <select
                  className="w-full p-2 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                >
                  <option value="public">Public - Anyone with the link</option>
                  <option value="private">Private - Only specific people</option>
                </select>
              </div>
            </div>

            <div className="mt-6 flex justify-end">
              <button
                onClick={() => setShowShareModal(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-800"
              >
                Close
              </button>
            </div>
          </div>
        </div>,
        document.body
      )}

      {showLogicConfig && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-xl shadow-xl max-w-md w-full p-6 m-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold text-gray-900">Question Logic</h3>
              <button
                onClick={() => setShowLogicConfig(false)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X size={20} />
              </button>
            </div>

            <div className="space-y-4">
              {/* Logic configuration content */}
              <p className="text-sm text-gray-600">
                Configure conditional logic for this question.
              </p>
              {/* Add your logic configuration UI here */}
            </div>

            <div className="mt-6 flex justify-end">
              <button
                onClick={() => setShowLogicConfig(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-800"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {showQuestionModal && createPortal(
        <div 
          className="fixed inset-0 bg-black/50 flex items-center justify-center" 
          style={{ zIndex: 99999 }}
        >
          <div className="bg-white rounded-2xl shadow-xl max-w-md w-full m-4 overflow-hidden">
            {/* Header */}
            <div className="px-6 py-4 border-b border-gray-100 flex items-center justify-between">
              <h3 className="text-lg font-semibold text-gray-900">
                Choose Question Type
              </h3>
              <button
                onClick={() => setShowQuestionModal(false)}
                className="text-gray-400 hover:text-gray-500 p-1 hover:bg-gray-50 rounded-full transition-colors"
              >
                <X size={20} />
              </button>
            </div>

            {/* Question Types Grid */}
            <div className="p-6">
              <div className="grid grid-cols-1 gap-3">
                {[
                  { 
                    type: 'multiple choice', 
                    label: 'Multiple Choice',
                    icon: ListChecks,
                    description: 'Let respondents choose from predefined options'
                  },
                  { 
                    type: 'checkbox list', 
                    label: 'Checkbox List',
                    icon: CheckSquare,
                    description: 'Allow respondents to select multiple options'
                  },
                  { 
                    type: 'short text', 
                    label: 'Short Text',
                    icon: TextCursor,
                    description: 'Collect brief text responses'
                  },
                  { 
                    type: 'long text', 
                    label: 'Long Text',
                    icon: AlignLeft,
                    description: 'Gather detailed text responses'
                  },
                  { 
                    type: 'numeric', 
                    label: 'Numeric',
                    icon: Hash,
                    description: 'Collect numerical data'
                  },
                  { 
                    type: 'star rating', 
                    label: 'Star Rating',
                    icon: Star,
                    description: 'Get feedback on a rating scale'
                  }
                ].map(({ type, label, icon: Icon, description }) => (
                  <button
                    key={type}
                    onClick={() => handleAddQuestion(type)}
                    className="flex items-center gap-4 p-4 text-left border border-gray-200 rounded-xl hover:border-blue-500 hover:bg-blue-50 transition-all group"
                  >
                    <div className="flex-shrink-0">
                      <div className="w-10 h-10 rounded-lg bg-blue-100 text-blue-600 flex items-center justify-center group-hover:bg-blue-600 group-hover:text-white transition-colors">
                        <Icon size={20} />
                      </div>
                    </div>
                    <div className="flex-1 min-w-0">
                      <div className="font-medium text-gray-900 group-hover:text-blue-600 transition-colors">
                        {label}
                      </div>
                      <div className="text-sm text-gray-500">
                        {description}
                      </div>
                    </div>
                    <ChevronRight size={16} className="text-gray-400 group-hover:text-blue-500" />
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>,
        document.body
      )}

      {showMediaModal && createPortal(
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center" style={{ zIndex: 50 }}>
          <div className="bg-white rounded-2xl shadow-xl max-w-4xl w-full m-4">
            <div className="px-6 py-4 border-b border-gray-100 flex items-center justify-between">
              <h3 className="text-lg font-semibold text-gray-900">Media Library</h3>
              <button
                onClick={() => setShowMediaModal(false)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X size={20} />
              </button>
            </div>
            
            <div className="p-6">
              {/* Upload Section */}
              <input
                type="file"
                accept="image/*"
                className="hidden"
                id="logo-upload"
                onChange={handleFileUpload}
              />
              
              <div 
                className="border-2 border-dashed border-gray-200 rounded-lg p-8 text-center cursor-pointer hover:border-blue-500 transition-colors"
                onClick={() => document.getElementById('logo-upload').click()}
              >
                <div className="flex flex-col items-center gap-2">
                  {isUploading ? (
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
                  ) : (
                    <>
                      <Image size={24} className="text-gray-400" />
                      <div className="text-sm font-medium text-gray-700">
                        Click to upload or drag and drop
                      </div>
                      <div className="text-xs text-gray-500">
                        SVG, PNG, JPG (max. 2MB)
                      </div>
                    </>
                  )}
                </div>
              </div>

              {/* Previous Logos Section */}
              <div className="mt-8">
                <h4 className="text-sm font-medium text-gray-700 mb-4">Previously Uploaded Logos</h4>
                {isLoadingLogos ? (
                  <div className="flex justify-center">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
                  </div>
                ) : (
                  <div className="grid grid-cols-4 gap-4">
                    {previousLogos.map((logo, index) => (
                      <div 
                        key={index}
                        className="relative group border rounded-lg p-2 hover:border-blue-500 cursor-pointer"
                        onClick={() => handleLogoSelect(logo)}
                      >
                        <img 
                          src={logo.url} 
                          alt={`Logo ${index + 1}`}
                          className="w-full h-24 object-contain"
                        />
                        <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center gap-2 rounded-lg">
                          <button 
                            className="text-white bg-white/20 p-2 rounded-full hover:bg-white/30"
                            onClick={() => handleLogoSelect(logo)}
                          >
                            <Check size={20} />
                          </button>
                          <button 
                            className="text-white bg-red-500/20 p-2 rounded-full hover:bg-red-500/30"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteMedia(logo.url);
                            }}
                          >
                            <Trash2 size={20} />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>,
        document.body
      )}

      {showBackgroundImageModal && createPortal(
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center" style={{ zIndex: 50 }}>
          <div className="bg-white rounded-2xl shadow-xl w-[800px] max-h-[80vh] m-4"> {/* Reduced width and added max height */}
            <div className="px-6 py-4 border-b border-gray-100 flex items-center justify-between">
              <h3 className="text-lg font-semibold text-gray-900">Choose Background Image</h3>
              <button
                onClick={() => setShowBackgroundImageModal(false)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X size={20} />
              </button>
            </div>

            {/* Tabs */}
            <div className="border-b border-gray-200">
              <nav className="flex -mb-px px-6">
                {[
                  { id: 'stock', label: 'Stock Photos', icon: ImagePlus },
                  { id: 'upload', label: 'Upload', icon: Upload },
                  { id: 'library', label: 'My Library', icon: Library }
                ].map(tab => (
                  <button
                    key={tab.id}
                    onClick={() => setActiveBackgroundTab(tab.id)}
                    className={`flex items-center gap-2 px-4 py-3 text-sm font-medium border-b-2 transition-colors ${
                      activeBackgroundTab === tab.id
                        ? 'border-blue-500 text-blue-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    }`}
                  >
                    {React.createElement(tab.icon, { size: 16 })}
                    {tab.label}
                  </button>
                ))}
              </nav>
            </div>
            
            {/* Scrollable content area */}
            <div className="p-6 overflow-y-auto" style={{maxHeight: 'calc(80vh - 140px)' }}> {/* Added overflow and max height */}
              {activeBackgroundTab === 'stock' && (
                <div className="grid grid-cols-2 gap-4">
                  {stockPhotos.map((photo) => (
                    <div 
                      key={photo.id}
                      className="relative group cursor-pointer rounded-lg overflow-hidden aspect-video" // Added aspect-video
                      onClick={() => handleBackgroundImageSelect({ url: photo.url })}
                    >
                      <img 
                        src={photo.url} 
                        alt="Background option"
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                        <button className="text-white bg-white/20 p-2 rounded-full">
                          <Check size={20} />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {activeBackgroundTab === 'upload' && (
                <div 
                  className="border-2 border-dashed border-gray-200 rounded-lg p-8 text-center cursor-pointer hover:border-blue-500 transition-colors"
                  onClick={() => document.getElementById('background-upload').click()}
                >
                  <input
                    type="file"
                    accept="image/*"
                    className="hidden"
                    id="background-upload"
                    onChange={handleBackgroundImageUpload}
                  />
                  <div className="flex flex-col items-center gap-2">
                    {isUploading ? (
                      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
                    ) : (
                      <>
                        <Image size={24} className="text-gray-400" />
                        <div className="text-sm font-medium text-gray-700">
                          Click to upload or drag and drop
                        </div>
                        <div className="text-xs text-gray-500">
                          SVG, PNG, JPG (max. 2MB)
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}

              {activeBackgroundTab === 'library' && (
                <div className="grid grid-cols-3 gap-4"> {/* Changed to 3 columns */}
                  {previousLogos.map((image, index) => (
                    <div 
                      key={index}
                      className="relative group border rounded-lg p-2 hover:border-blue-500 cursor-pointer aspect-video" // Added aspect-video
                      onClick={() => handleBackgroundImageSelect(image)}
                    >
                      <img 
                        src={image.url} 
                        alt={`Image ${index + 1}`}
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center gap-2 rounded-lg">
                        <button 
                          className="text-white bg-white/20 p-2 rounded-full hover:bg-white/30"
                          onClick={() => handleBackgroundImageSelect(image)}
                        >
                          <Check size={20} />
                        </button>
                        <button 
                          className="text-white bg-red-500/20 p-2 rounded-full hover:bg-red-500/30"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteMedia(image.url);
                          }}
                        >
                          <Trash2 size={20} />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>,
        document.body
      )}
    </div>
  );
};

export default SurveyDesigner;